import React from "react";
import $ from "jquery";
import SwitchItem from "./SwitchItem";

class SwitchableContainer extends React.Component{

    switch = () => {
        var activeIndex = Array.from(document.getElementsByClassName("switch-container")[0].children).indexOf(document.getElementsByClassName("switch-item active")[0]);
        activeIndex++;
        if(activeIndex > $("#sw-container").children().length-1){
            activeIndex = 0;
        }
        $("#sw-container>div.active").removeClass("active");
        var selectedElement = $("#sw-container").children().eq(activeIndex);
        selectedElement.addClass("active");

        selectedElement = document.querySelector('body');
        selectedElement.scrollIntoView({behavior:'smooth',block:'end'});
    }
    
    render(){
        return(
            <div style={{"display":"flex","justifyContent":"center","paddingBottom":"40px"}}>
                <div id="sw-container" className="switch-container" style={{"marginTop":"10px"}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}export default SwitchableContainer