import React from "react";
import { SuspenseImg } from "../mics/SuspenseImg";

class ProfileIcon extends React.Component{
    render(){
        return(
            <>
                <SuspenseImg alt={"background"} style={{"height":"44vh","width":"100%","objectFit":"cover"}} src={require("../../img/slide3.png")} />
                {/* <SuspenseImg alt={"background"} style={{"height":"44vh","width":"100%","objectFit":"cover"}} src={"../../img/slide3.png"} /> */}
                <div style={{"width":"40vh","height":"40vh","backgroundColor":"white","borderRadius":"50%","position":"absolute","top":"50%","left":"50%","transform":"translate(-50%,-50%)"}}>
                    <div className="image-cropper" style={{"margin":"0 auto","width":"95%","height":"95%","top":"50%","transform":"translateY(-50%)","position":"relative","overflow":"hidden","borderRadius":"50%"}}>
                        <SuspenseImg alt={"Falmingo"} src={require("../../img/profile.png")} />
                        {/* <img style={{"display":"inline","margin":"0 auto","height":"100%","width":"auto"}} src={require('../../img/profile.png')} alt="Krystof Richtermoc" /> */}
                    </div>
                </div>
            </>
        )
    }
}export default ProfileIcon;