import React from "react";
import ProfileIcon from "../components/profile/ProfileIcon";
import {SectionRow} from "../components/mainparts/SectionRow";
import {ProjectItem} from "../components/portfolio/ProjectItem";

class MainPage extends React.Component{

    openNav = () => {
        document.getElementById("mySidenav").style.width = "250px";
    }

    componentDidMount(){
        console.log("Main page mounted");
    }

    


    render(){

        return(
            <>
                <div>
                    <ProfileIcon />
                </div>
                <h1 className="mainTitle" style={{"paddingBottom":"6vh"}}>Kryštof Richtermoc</h1>

                <SectionRow>
                    <div id="sw-container" className="switch-container" style={{"margin":"auto"}}>
                        <div className="switch-item active">
                            <p style={{"display":"block","padding":"26px 86px"}}>Welcome to my portfolio page! You can find here latest news about my projects or links on my social media. Also many usefull links</p>
                        </div>
                    </div>
                </SectionRow>

                <SectionRow>
                    <div className="mobileFlex">
                        <ProjectItem link={"/falmingo"} icon={require('../img/falmingoicon.png')} projectName={"Falmingo"} 
                            description={"Casual ortographic 3D platform game"}
                        />
                        <ProjectItem link={"/okago"} icon={require('../img/develop.png')} projectName={"Okago"}
                            description={"Multiplayer location based game where you can be a part of world"}
                        />
                        <ProjectItem link={"/project-b3"} icon={require('../img/simplecopy.png')} projectName={"Project-B3"}
                            description={"FPS multiplayer game in anime cartoon style"}
                        />
                    </div>
                </ SectionRow>


            </>
        );
    }
}export default MainPage;