import React from "react";

export default function RowCentered(props){

    return(
        <div style={{"display":"block"}}>
            <div style={{"margin":"0 auto","textAlign":"center","padding-bottom":" 5vh"}}>
                {props.children}
            </div>
        </div>
    )
}