import React from "react";
import '../../styles/Loader.css';

export default function LoadIcon(){
    
    return(
        <div id="loader">
            <div className='loader3'>
                <div className='spinner2'>
                    <div className='container-B'>
                        <div className='hex0'></div>
                        <div className='hex120'></div>
                        <div className='hex240'></div>
                        <div className='spinner2'>
                            <div className='container-B'>
                                <div className='hex0'></div>
                                <div className='hex120'></div>
                                <div className='hex240'></div>
                                <div className='spinner2'>
                                    <div className='container-B'>
                                        <div className='hex0'></div>
                                        <div className='hex120'></div>
                                        <div className='hex240'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 