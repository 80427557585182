import React from "react";
import ProjectIcon from "../components/project/ProjectIcon";

import "./../styles/App.scss"
import SwitchableContainer from "../components/mainparts/SwitchableContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import RowCentered from "../components/mainparts/RowCentered";
import { SuspenseImg } from "../components/mics/SuspenseImg";
import SwitchItem from "../components/mainparts/SwitchItem";

class Falmingo extends React.Component{
    constructor(props){
        super(props); 
        this.refSwitchContainer = React.createRef();
    }

    render(){
        console.log(this.refSwitchContainer);
        return(
            <div>
                <div >
                    <SuspenseImg alt={"background"} style={{"height":"44vh","width":"100%","objectFit":"cover"}} src={require("../img/imgBack.jpg")} />
                    <ProjectIcon imgsrc={require("../img/falmingoicon.png")} />
                </div>
                <h1 className="mainTitle" style={{"paddingTop":"28vh"}}>Falmingo</h1>
                <a className="ownerTitle" href={"/"}>
                    <h4>Kryštof Richtermoc</h4>
                </a>
                <RowCentered>
                    <div style={{"display":"flex","justifyContent":"space-around","width":"24vh","margin":"0 auto"}}>
                        <FontAwesomeIcon color="white" className="platformIcon" icon={brands('apple')} size="2x" />
                        <FontAwesomeIcon color="white" className="platformIcon" icon={brands('android')} size="2x" />
                    </div>
                </RowCentered>

                <div style={{"display":"flex","justifyContent":"center"}}>
                    <div className="switch-button">
                        <input className="switch-button-checkbox" type="checkbox" onClick={()=>{this.refSwitchContainer.current.switch()}}></input>
                        <label className="switch-button-label" htmlFor=""><span className="switch-button-label-span">About</span></label>
                    </div>
                </div>

                <SwitchableContainer ref={this.refSwitchContainer}> 
                    <div className="switch-item active">
                        <p style={{"display":"block","padding":"26px 86px"}}>Hyper-Casual relaxing game where you can help Mr. PaperClip with his work, challenge your friends, beat best score and be Falmingo Master! Enjoy retro vibe trip!</p>
                    </div>

                    <SwitchItem>
                        <table className="app-info-table" style={{"margin":"0 auto"}}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Latest release</b></td>
                                    <td><b>17.07. 2022</b></td>
                                </tr>
                                <tr>
                                    <td><b>Project state</b></td>
                                    <td><b>Alpha</b></td>
                                </tr>
                                <tr>
                                    <td><b>App size</b></td>
                                    <td><b>~120MB</b></td>
                                </tr>
                                <tr>
                                    <td><b>Platforms</b></td>
                                    <td><b>iOS, Android</b></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </SwitchItem>
                </SwitchableContainer>

                <RowCentered>
                    <div className="getItButtonContainer">
                        <div className="getItButton">
                            <a href="https://apps.apple.com/us/app/flamingo-fares/id1487465395?itsct=apps_box_badge&amp;itscg=30200" style={{"display": "inline-block", "overflow": "hidden", "borderRadius": "13px", "width": "100%"}} >
                                <SuspenseImg src={require("../img/appleDownload.png")} alt="Get it on the App Store" style={{"borderRadius": "13px", "width":"100%"}}/>
                            </a>
                        </div>
                        <div className="getItButton">
                            <a href='https://play.google.com/store/apps/details?id=com.ScriptCorp.Flamingo&hl=cs&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <SuspenseImg style={{"width":"100%"}} alt='Get it on Google Play' src={require("../img/en_badge_web_generic.png")}/>
                            </a>
                        </div>
                    </div>
                </RowCentered>

                <RowCentered>
                    <div className="bottomLinks">
                        <a href="/">Support</a>
                        <a href="/falmingo/privacy-policy">Privacy Policy</a>
                        <a href="/">Credits</a>
                    </div>
                </RowCentered>
            </div>
        );
    }
}export default Falmingo;