import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import MainPage from './pages/MainPage';
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import Falmingo from './pages/Falmingo';
import './styles/App.css';
import './styles/AppMobile.scss';
import LoadIcon from './components/mainparts/LoadIcon';
import FalmingoPrivacyPolicy from './pages/FalmingoPrivacyPolicy';
import ProjectB3 from './pages/ProjectB3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<LoadIcon />}>
      <Routes>

          <Route path="/" element={<MainPage />} />
          <Route path="/project-b3" element={<ProjectB3 />} />
          <Route path="/falmingo" element={<Falmingo />} />
          <Route path="/app-ads.txt" onEnter={()=>{window.location.reload()}} />
          <Route path="/servers.txt" onEnter={()=>{window.location.reload()}} />
          <Route path="/falmingo/privacy-policy" exact element={<FalmingoPrivacyPolicy />} />
      </Routes>
    </Suspense>

  </BrowserRouter>
);