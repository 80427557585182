import React from "react";
import { SuspenseImg } from "../mics/SuspenseImg";

export default function ProjectIcon(props){

        return(
            <div style={{"width":"40vh","height":"40vh","backgroundColor":"white","borderRadius":"60px","position":"absolute","top":"50%","left":"50%","transform":"translate(-50%,-50%)"}}>
                <div className="image-cropper" style={{"margin":"0 auto","width":"95%","height":"95%","top":"50%","transform":"translateY(-50%)","position":"relative","overflow":"hidden","borderRadius":"60px"}}>
                    <SuspenseImg alt={"Krystof Richtermoc"} src={props.imgsrc} />
                    {/* <img style={{"display":"inline","margin":"0 auto","height":"100%","width":"auto"}} src={props.imgsrc} alt="Krystof Richtermoc" /> */}
                </div>
            </div>
        )
}