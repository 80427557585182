import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SuspenseImg } from "../mics/SuspenseImg"

export function ProjectItem(props){
    return(
        <div className="projectItem">
            <div style={{"display":"flex","justifyContent":"space-between"}}>
                <SuspenseImg style={{"height":"80px","width":"auto","borderRadius":"21px"}} src={props.icon} alt="" />
                <div className="projectItemPlatform">
                    <FontAwesomeIcon icon={brands('apple')} />
                    <FontAwesomeIcon icon={brands('android')} />
                </div>
            </div>
            <h1 style={{"paddingTop":"8px","textDecoration":"underline"}}>{props.projectName}</h1>
            <span style={{"display":"block","paddingTop":"8px","paddingBottom":"20px"}}>{props.description}</span>
            <Link to={props.link} className="linkButton">More</Link>
        </div>)
}