import React from "react";
import ProjectIcon from "../components/project/ProjectIcon";

import "./../styles/App.scss"
import SwitchableContainer from "../components/mainparts/SwitchableContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import RowCentered from "../components/mainparts/RowCentered";
import { SuspenseImg } from "../components/mics/SuspenseImg";
import SwitchItem from "../components/mainparts/SwitchItem";

class ProjectB3 extends React.Component{
    constructor(props){
        super(props); 
        this.refSwitchContainer = React.createRef();
    }

    render(){
        console.log(this.refSwitchContainer);
        return(
            <div>
                <div >
                    <SuspenseImg alt={"background"} style={{"height":"44vh","width":"100%","objectFit":"cover"}} src={require("../img/imgBack.jpg")} />
                    <ProjectIcon imgsrc={require("../img/falmingoicon.png")} />
                </div>
                <h1 className="mainTitle" style={{"paddingTop":"28vh"}}>Project-B3</h1>
                <a className="ownerTitle" href={"/"}>
                    <h4>Kryštof Richtermoc</h4>
                </a>
                <RowCentered>
                    <div style={{"display":"flex","justifyContent":"space-around","width":"24vh","margin":"0 auto"}}>
                        <FontAwesomeIcon color="white" className="platformIcon" icon={brands('windows')} size="2x" />
                    </div>
                </RowCentered>

                <div style={{"display":"flex","justifyContent":"center"}}>
                    <div className="switch-button">
                        <input className="switch-button-checkbox" type="checkbox" onClick={()=>{this.refSwitchContainer.current.switch()}}></input>
                        <label className="switch-button-label" htmlFor=""><span className="switch-button-label-span">About</span></label>
                    </div>
                </div>

                <SwitchableContainer ref={this.refSwitchContainer}> 
                    <div className="switch-item active">
                        <p style={{"display":"block","padding":"26px 86px"}}>FPS multiplayer game in cartoon anime style where you will have make your custom ability set and prove you are the best Chuong mister. Game will test your reflexes and gives you opportunity to be a part of it by special feature to upload sound by your choice as casting sound.</p>
                    </div>

                    <SwitchItem>
                        <table className="app-info-table" style={{"margin":"0 auto"}}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Latest release</b></td>
                                    <td><b>26.05. 2023</b></td>
                                </tr>
                                <tr>
                                    <td><b>Project state</b></td>
                                    <td><b>Closed-beta</b></td>
                                </tr>
                                <tr>
                                    <td><b>App size</b></td>
                                    <td><b>~30MB</b></td>
                                </tr>
                                <tr>
                                    <td><b>Platforms</b></td>
                                    <td><b>Windows</b></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </SwitchItem>
                </SwitchableContainer>

                <RowCentered>
                    <div className="bottomLinks">
                        <a href="/">Support</a>
                        <a href="/falmingo/privacy-policy">Privacy Policy</a>
                        <a href="/">Credits</a>
                    </div>
                </RowCentered>
                <script type="text/javascript" src="https://s.skimresources.com/js/240628X1723854.skimlinks.js"></script>
            </div>
        );
    }
}export default ProjectB3;